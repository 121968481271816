import "./App.css";
import Header from "./components/Header";
import Banner from "./components/Banner";
import About from "./components/About";
import Tokenomics from "./components/Tokenomics";
import Community from "./components/Community";

function App() {
  return (
    <div className="app">
      <Header />
      <div className="app">
        <Banner />
        <About />
        <Tokenomics />
        <Community />
      </div>
    </div>
  );
}

export default App;
