import "./Community.css";
import Aos from "aos";
import founderImg from "./../assets/signature-black134.png"; // freestyle script font

function Community() {
  const twitterLink = "";
  const redditLink = "";
  const telegramLink = "";
  Aos.init();

  return (
    <div data-aos="zoom-in-left" data-aos-duration="1000" data-aos-once="true">
      <div id="community" className="community">
        <h2>Community</h2>
        Join the official communities to find out the latest. Let us take off to
        the moon together.
      </div>
      <div className="community__signature">
        <img src={founderImg} width="130" alt="Kevin" />
      </div>
      <div className="community__links">
        <a href={twitterLink} target="_blank" rel="noreferrer nofollow">
          <i
            className="fab fa-twitter fa-3x twitter-color image-box"
            title="Twitter"
          />
        </a>
        <a href={redditLink} target="_blank" rel="noreferrer nofollow">
          <i
            className="fab fa-reddit-alien fa-3x reddit-color image-box"
            title="Reddit"
          />
        </a>
        <a href={telegramLink} target="_blank" rel="noreferrer nofollow">
          <i
            className="fab fa-telegram-plane fa-3x telegram-color image-box"
            title="Telegram"
          />
        </a>
      </div>
    </div>
  );
}

export default Community;
