import "./About.css";
import "aos/dist/aos.css";
import Aos from "aos";
import { Container, Row, Col } from "react-bootstrap";
import coinStackImg from "./../assets/about-coin-stack.png";

function About() {
  const contractAddress = "0xbFc6d81982C62F0BE09E39642D7C89bdf99981DC";
  const contractLink = "https://etherscan.io/address/" + contractAddress;
  Aos.init();

  return (
    <div id="about">
      <Container fluid="true" className="about__coin-stack">
        <Col
          className="about about__coin-stack-col-1"
          data-aos="zoom-in-right"
          data-aos-duration="1000"
          data-aos-once="true"
        >
          <Row>
            <h2>Our Project</h2>
            Junedog is a mixed-breed DeFi cryptocurrency with a unique reward
            system. It is a fair-launched project and descendant of the Inu
            family and Dogecoin. As this progressive community evolves, its
            mission to rescue dogs like June by donating to no-kill animal
            shelters will be possible. If you want to adopt $JUNE, use the 
            above button.
          </Row>
          <Row>
            <h3>Contract</h3>
            <div>
              <a href={contractLink} target="_blank" rel="noreferrer nofollow">
                {contractAddress}
              </a>{" "}
              <button
                onClick={() => navigator.clipboard.writeText(contractAddress)}
              >
                <i className="fas fa-copy fa-2x" title="Copy" />
              </button>
            </div>
          </Row>
        </Col>
        <Col
          className="about about__coin-stack-col-2"
          data-aos="zoom-in-left"
          data-aos-duration="1000"
          data-aos-once="true"
        >
          <Row>
            <img src={coinStackImg} alt="Coin Stack" />
          </Row>
        </Col>
      </Container>
    </div>
  );
}

export default About;
