import "./Banner.css";
import { Container, Row, Col } from "react-bootstrap";
import heroImg from "./../assets/banner-hero.png";

function Banner() {
  return (
    <div>
      <Container fluid="true" className="banner__hero">
        <Row className="banner__hero-row">
          <Col className="banner__hero-col-1">
            <img className="banner__hero-image" src={heroImg} alt="Hero" />
          </Col>
          <Col className="banner__hero-col-2">
            <h2>Junedog.finance</h2>
            <h1>A Decentralized Meme Token that is Owned by the Community and Participant in the Charity Revolution.</h1>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Banner;
