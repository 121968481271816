import "./Tokenomics.css";
import "aos/dist/aos.css";
import Aos from "aos";
import { Container, Row, CardGroup, Card } from "react-bootstrap";
import donateImg from "./../assets/tokenomics-donate-yellow.svg";
import handshakeImg from "./../assets/tokenomics-rocket-purple.svg";
import lockImg from "./../assets/tokenomics-lock-green.svg";
import circleImg from "./../assets/tokenomics-circle-x-red.svg";
import whaleImg from "./../assets/tokenomics-whale-purple.svg";
import giftImg from "./../assets/tokenomics-gift-blue.svg";
import fireImg from "./../assets/tokenomics-fire-red.svg";
import heartImg from "./../assets/tokenomics-holding-heart-pink.svg";

function Tokenomics() {
  const renounceLink =
    "https://etherscan.io/tx/0x873bf3dbbbb3d52b4109399d9630b5187ff8893d17708228a5c51567f4e18318#eventlog";
  const walletLink =
    "https://etherscan.io/token/0xbFc6d81982C62F0BE09E39642D7C89bdf99981DC?a=0x420d5ccbb0267af9f5170e9cf82ae5a0ef3cca5e";
  const liquidityLink =
    "https://app.unicrypt.network/amm/uni-v2/pair/0x1d8eF07a372754cA7Ae5a68eC9c42df776729313";
  Aos.init();

  return (
    <div
      id="tokenomics"
      className="tokenomics"
      data-aos="zoom-out-up"
      data-aos-duration="1000"
      data-aos-once="true"
    >
      <Container fluid="true">
        <Row>
          <h2>Tokenomics</h2>
        </Row>
        <Row>
          <CardGroup className="tokenomics__card-group">
            <Card className="tokenomics__card">
              <Card.Img
                className="tokenomics__card-image"
                variant="top"
                src={donateImg}
              />
              <Card.Body>
                <Card.Title className="tokenomics__card-title">
                  10T total supply that is capped
                </Card.Title>
              </Card.Body>
            </Card>
            <Card className="tokenomics__card">
              <Card.Img
                className="tokenomics__card-image"
                variant="top"
                src={handshakeImg}
              />
              <Card.Body>
                <Card.Title className="tokenomics__card-title">
                  Fair-launch on Uniswap
                </Card.Title>
              </Card.Body>
            </Card>
            <Card className="tokenomics__card">
              <Card.Img
                className="tokenomics__card-image"
                variant="top"
                src={lockImg}
              />
              <Card.Body>
                <Card.Title className="tokenomics__card-title">
                  Liquidity{" "}
                  <a
                    href={liquidityLink}
                    target="_blank"
                    rel="noreferrer nofollow"
                  >
                    locked
                  </a>{" "}
                  3 years
                </Card.Title>
              </Card.Body>
            </Card>
            <Card className="tokenomics__card">
              <Card.Img
                className="tokenomics__card-image"
                variant="top"
                src={circleImg}
              />
              <Card.Body>
                <Card.Title className="tokenomics__card-title">
                  Ownership{" "}
                  <a
                    href={renounceLink}
                    target="_blank"
                    rel="noreferrer nofollow"
                  >
                    renounced
                  </a>
                </Card.Title>
              </Card.Body>
            </Card>
            <Card className="tokenomics__card">
              <Card.Img
                className="tokenomics__card-image"
                variant="top"
                src={whaleImg}
              />
              <Card.Body>
                <Card.Title className="tokenomics__card-title">
                  Transactions limited to 50B
                </Card.Title>
              </Card.Body>
            </Card>
            <Card className="tokenomics__card">
              <Card.Img
                className="tokenomics__card-image"
                variant="top"
                src={fireImg}
              />
              <Card.Body>
                <Card.Title className="tokenomics__card-title">
                  Deflationary 2% burned
                </Card.Title>
              </Card.Body>
            </Card>
            <Card className="tokenomics__card">
              <Card.Img
                className="tokenomics__card-image"
                variant="top"
                src={giftImg}
              />
              <Card.Body>
                <Card.Title className="tokenomics__card-title">
                  Unique reward system 0.5%
                </Card.Title>
              </Card.Body>
            </Card>
            <Card className="tokenomics__card">
              <Card.Img
                className="tokenomics__card-image"
                variant="top"
                src={heartImg}
              />
              <Card.Body>
                <Card.Title className="tokenomics__card-title">
                  No-kill animal shelters{" "}
                  <a
                    href={walletLink}
                    target="_blank"
                    rel="noreferrer nofollow"
                  >
                    wallet
                  </a>
                </Card.Title>
              </Card.Body>
            </Card>
          </CardGroup>
        </Row>
      </Container>
    </div>
  );
}

export default Tokenomics;
