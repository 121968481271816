import "./Header.css";
import React, { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { CSSTransition } from "react-transition-group";
import logoImg from "./../assets/logo128.svg";

function Header() {
  const [isNavVisible, setNavVisibility] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const uniswapLink =
    "https://app.uniswap.org/#/swap?outputCurrency=0xbFc6d81982C62F0BE09E39642D7C89bdf99981DC";

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 700px)");
    mediaQuery.addListener(handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  const handleMediaQueryChange = (mediaQuery) => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  const toggleNav = () => {
    setNavVisibility(!isNavVisible);
  };

  return (
    <header className="header">
      <img className="header__logo" src={logoImg} alt="Logo" />
      <div className="header__get-wrapper">
        <a href={uniswapLink} target="_blank" rel="noreferrer nofollow">
          <button className="header__get">Get $JUNE</button>
        </a>
      </div>
      <CSSTransition
        in={!isSmallScreen || isNavVisible}
        timeout={350}
        classNames="header__nav-animation"
        unmountOnExit
      >
        <nav className="header__nav">
          <Switch>
            <Route>
              <HashLink onClick={toggleNav} smooth to="/#top">
                Home
              </HashLink>
            </Route>
          </Switch>
          <Switch>
            <Route>
              <HashLink onClick={toggleNav} smooth to="/#about">
                About
              </HashLink>
            </Route>
          </Switch>
          <Switch>
            <Route>
              <HashLink onClick={toggleNav} smooth to="/#tokenomics">
                Tokenomics
              </HashLink>
            </Route>
          </Switch>
          <Switch>
            <Route>
              <HashLink onClick={toggleNav} smooth to="/#community">
                Community
              </HashLink>
            </Route>
          </Switch>
        </nav>
      </CSSTransition>
      <button onClick={toggleNav} className="header__menu">
        <i className="fas fa-bars fa-3x" title="Menu" />
      </button>
    </header>
  );
}

export default Header;
